import Rellax from "rellax";
import VanillaTilt from "vanilla-tilt";
import Header from "./header_sticky";
import Cookie from "./cookie";
import AOS from "aos"

if (document.getElementsByClassName("rellax").length > 0) {
    const rellax = new Rellax(".rellax");
}

VanillaTilt.init(document.getElementsByClassName("tilt-element"), {
    max: 25,
    speed: 400
});


AOS.init();
